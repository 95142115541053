



































import Vue from 'vue'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default Vue.extend({
    data: () => ({
        dateRange: {
            startDate: new Date() as Date,
            endDate: new Date() as Date,
        },
        locale: {
            direction: 'ltr',
            format: 'dd/mm/yyyy',
            separator: ' - ',
            applyLabel: 'Aplicar',
            cancelLabel: 'Cancelar',
            weekLabel: 'W',
            customRangeLabel: 'Favoritos',
            daysOfWeek: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
            monthNames: [
                'Jan',
                'Fev',
                'Mar',
                'Abr',
                'Mai',
                'Jun',
                'Jul',
                'Ago',
                'Set',
                'Out',
                'Nov',
                'Dez',
            ],
            firstDay: 0,
        },
        ranges: {},
        timeout: null as any,
    }),
    props: {
        disabled: {
            type: Boolean,
            default: false
        }
    },
    components: { DateRangePicker },
    mounted() {
        this.setRanges()
        this.dateRange = this.getRangeCurrentMonth()
    },
    methods: {
        onDateUpdate(date) {
            this.$emit('change',date)
        },
        applyDate() {
             clearTimeout(this.timeout)
            this.timeout = setTimeout(() => {
                (this.$refs.picker as any).clickedApply()
            }, 300)
        },
        eventBefore() {
            this.applyDate()
            let date = this.dateRange.startDate
            date.setDate(date.getDate() - 1)
            this.dateRange.startDate = new Date(date)
        },
        eventNext() {
            this.applyDate()
            let date = this.dateRange.endDate
            date.setDate(date.getDate() + 1)
            this.dateRange.endDate = new Date(date)
        },
        setRanges() {
            let today = new Date()
            let todayEnd = new Date()
            today.setHours(0, 0, 0)
            todayEnd.setHours(23, 59, 59)

            let yesterday = new Date()
            let yesterdayEnd = new Date()
            yesterday.setDate(yesterday.getDate() - 1)
            yesterdayEnd.setDate(yesterdayEnd.getDate() - 1)
            yesterday.setHours(0, 0, 0, 0)
            yesterdayEnd.setHours(23, 59, 59)
            let lastWeek = new Date(today.getTime() + 60 * 60 * 24 * 6 * 1000)
            lastWeek.setHours(23, 59, 59)
            let fisrtWeek = new Date(
                today.getTime() - 60 * 60 * 24 * today.getDay() * 1000
            )
            fisrtWeek.setHours(0, 0, 0)

            this.ranges = {
                Hoje: [today, todayEnd],
                Ontem: [yesterday, yesterdayEnd],
                'Este mês': [
                    this.getRangeCurrentMonth().startDate,
                    this.getRangeCurrentMonth().endDate,
                ],
                'Mês anterior': [
                    new Date(today.getFullYear(), today.getMonth() - 1, 1),
                    new Date(
                        today.getFullYear(),
                        today.getMonth(),
                        0,
                        23,
                        59,
                        59
                    ),
                ],
                'Esta semana': [fisrtWeek, lastWeek],
                'Este ano': [
                    new Date(today.getFullYear(), 0, 1),
                    new Date(
                        today.getFullYear(),
                        today.getMonth(),
                        today.getDate(),
                        23,
                        59,
                        59
                    ),
                ],
            }
        },
        getRangeCurrentMonth() {
            let today = new Date()
            today.setHours(0, 0, 0, 0)

            return {
                startDate: new Date(today.getFullYear(), today.getMonth(), 1),
                endDate: new Date(
                    today.getFullYear(),
                    today.getMonth() + 1,
                    0,
                    23,
                    59,
                    59
                ),
            }
        },
    },
})
