































































import Vue from 'vue'
import NavCalendar from '@/components/NavCalendar.vue'
import VueApexCharts from 'vue-apexcharts'
import api, { routers } from '@/api'
import { notification } from '@/utils'

interface ICounter {
    title: string,
    value: number,
    variant?: string
}

export default Vue.extend({
    components: { NavCalendar, apexchart: VueApexCharts },
    data: () => ({
        loadingDashboard: false,
        counters: [] as Array<ICounter>,
        dataChart: {
            chartTreatment: {
                data: [],
                options: {
                    chart: {
                        type: 'line',
                    },
                    title: {
                        text: 'Solicitações por período',
                    },
                    subtitle: {
                        text: 'Solicitações pendentes, tratadas e descartadas',
                    },
                    xaxis: {
                        categories: [],
                        title: {
                            text: 'Período',
                        },
                    },
                    yaxis: {
                        title: {
                            text: 'Solicitações',
                        },
                        // min: 1,
                        // max: 6000,
                    },
                },
            },
        },
        chartOptionsDefault: {
            chart: {
                height: 350,
                type: 'line',
                toolbar: {
                    show: true,
                },
            },
            colors: ['#6554c0', '#dc3545', '#36b37e', '#344563', '#aeb0b3'],
            dataLabels: {
                enabled: true,
            },
            stroke: {
                curve: 'smooth',
                width: 2
            },
            title: {
                text: '',
                align: 'left',
            },
            grid: {
                borderColor: '#e9ecef',
                row: {
                    colors: ['#e9ecef', 'transparent'],
                    opacity: 0.5,
                },
            },
            markers: {
                size: 1,
            },
        },
    }),
    mounted() {
        const range = (this.$refs.navcalendar as any).getRangeCurrentMonth()
        this.getData(range.startDate, range.endDate)
    },
    methods: {
        getChartOptions(chartName: string) {
            let copyOptions = Object.assign(
                {},
                this.chartOptionsDefault,
                this.dataChart[chartName].options
            )
            return copyOptions
        },
        onChangeDate(date: any) {
            this.getData(date.startDate, date.endDate)
        },
        async getData(startDate: Date, endDate: Date) {
            this.loadingDashboard = true
            try {
                const format = 'YYYY-MM-DD HH:mm:ss'
                const mmt = (this as any).$moment
                const startDateStr = mmt(startDate).format(format)
                const endDateStr = mmt(endDate).format(format)

                const { data } = await api.get(routers.Requests.DASHBOARD, {
                    params: {
                        startDate: startDateStr,
                        endDate: endDateStr,
                    },
                })

                this.dataChart.chartTreatment.data = data.chart.datasets
                this.dataChart.chartTreatment.options.xaxis.categories =
                    data.chart.categories

                this.counters = data.counters

            } catch (err) {
                notification.msgBoxOk(
                    'ERROR',
                    err.message || err.errorMessage || 'Falha na requisição'
                )
            } finally {
                this.loadingDashboard = false
            }
        },
    },
})
